import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const ASINs_Performance = () => {
  return (
    <Layout>
      <Seo
        title="Amazon ASIN Performance"
        description="Amazon ASIN Performance Sales Rank and search suppressed"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">Amazon ASIN Performance </Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszer-performance.jpg"
                    alt="Anazon ASIN performance"
                    placeholder="tracedSVG"
                    layout="constrained"
                    aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      Get the performance of your ASIN sales rank and compared
                      in daily, weekly, and monthly
                    </li>
                    <li>
                      azszero will find the ASINs that have been suppressed from
                      amazon search and notify you that something went wrong
                      then instruct you of what steps you might need to take to
                      bring the search results back.
                    </li>
                    <li>
                      An overall performance chart will be present to give you
                      an idea of your overall performance score.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default ASINs_Performance
